/** Sedrian Codex Updated Styles **/

.codex-wrapper {
  padding: 40px 20px;
  background: #12081f;
}

.codex-title-animated {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  background: linear-gradient(135deg, #54aeff, #f421ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.codex-top-panel-title {
  text-align: left;
  color: #ffc800;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 10px;
  font-weight: 600;
}

.codex-horizontal-line {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 30px 0 20px;
}

.codex-user-panel {
  margin: 10px 0 10px;
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  color: #fff;
}

.codex-user-panel ul {
  padding-left: 20px;
  margin: 0;
}

.codex-user-panel li {
  margin-bottom: 6px;
  font-size: 15px;
  color: #ddd;
}

.scroll-highlight {
  color: #ffc800;
}

.codex-scroll-section {
  margin-bottom: 50px;
}

.codex-scroll-header {
  margin-bottom: 15px;
  padding-left: 10px;
}

.codex-scroll-title {
  font-size: 20px;
  font-weight: 600;
  color: #ffc800;
  margin-bottom: 5px;
}

.codex-scroll-desc {
  color: #bbb;
  font-size: 14px;
}

.codex-scroll-strip {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  padding: 10px 5px;
  scroll-behavior: smooth;
}

.codex-scroll-strip::-webkit-scrollbar {
  height: 6px;
}

.codex-scroll-strip::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.codex-profile-card {
  min-width: 180px;
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  flex-shrink: 0;
}

.codex-profile-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  margin-bottom: 8px;
}

.codex-profile-alias {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 4px;
}

.codex-rank-label {
  font-size: 12px;
  color: #ffc800;
  margin-bottom: 6px;
}

.codex-profile-score {
  font-size: 13px;
  color: #f421ff;
  margin-bottom: 8px;
}

.codex-view-profile {
  font-size: 12px;
  color: #54aeff;
  text-decoration: none;
}

.codex-view-profile:hover {
  text-decoration: underline;
}

.rank-corner-badge {
  position: absolute;
  top: 6px;
  left: 6px;
  background: #333;
  padding: 2px 6px;
  font-size: 11px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.badge-rank-1 {
  background: linear-gradient(135deg, #ffd700, #ffb700);
  color: #1a102d;
}

.badge-rank-2 {
  background: linear-gradient(135deg, #c0c0c0, #aaa);
  color: #1a102d;
}

.badge-rank-3 {
  background: linear-gradient(135deg, #cd7f32, #b87333);
  color: #1a102d;
}

.codex-scroll-controls {
  margin-top: 10px;
  text-align: left;
  padding-left: 10px;
}

.codex-show-more-btn {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
  transition: background 0.3s;
}

.codex-show-more-btn:hover {
  background: #54aeff;
  color: #1a102d;
  border-color: transparent;
}