/* General Styles */
.details-page {
  max-width: 1280px;
  margin: 0 auto;
  padding: 60px 20px 100px;
  color: #fff;
}

/* Header Section */
.details-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.topSection {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
}

/* Image Container */
.imageContainer {
    border-radius: 15px;
    box-shadow: 0 4px 10px #0006;
    cursor: pointer;
    flex: 0 0 500px;
    height: 500px;
    overflow: hidden;
    padding: 10px;
    background: -webkit-linear-gradient(320deg, #54aeff, #f421ff, #54aeff, #f421ff);
    background: -moz-linear-gradient(320deg, #54aeff, #f421ff, #54aeff, #f421ff);
    background: -o-linear-gradient(320deg, #54aeff, #f421ff, #54aeff, #f421ff);
    background: linear-gradient(320deg, #54aeff, #f421ff, #54aeff, #f421ff);
    background-size: 300% 300%;
    animation: gradientBG_c1 10s ease infinite;
}

@keyframes gradientBG_c1 {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.modalImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Info Container */
.info-container {
  flex: 1;
  background: linear-gradient(135deg, #242233, #302e44);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.info-container h2 {
  font-size: 2rem;
  color: #54aeff;
  margin-bottom: 10px;
  text-align: left;
      background: linear-gradient(135deg, #54aeff, #f421ff, #54aeff, #f421ff, #54aeff, #f421ff, #54aeff, #f421ff);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textGradient_c1 10s ease infinite;
}

    @keyframes textGradient_c1 {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }

.info-container p {
  font-size: 1rem;
  margin: 0px 0px 15px;
}

.button-group {
  margin: 15px 0;
  display: flex;
  gap: 10px;
}

.navbar-btn {
background: rgb(84,174,255);
background: -moz-linear-gradient(0deg, rgba(84,174,255,1) 0%, rgba(244,33,255,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(84,174,255,1) 0%, rgba(244,33,255,1) 100%);
background: linear-gradient(0deg, rgba(84,174,255,1) 0%, rgba(244,33,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#54aeff",endColorstr="#f421ff",GradientType=1);
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    padding: 10px 25px;
    transition: transform .2s, box-shadow .3s;
}

.navbar-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0px 5px 15px rgba(84, 174, 255, 0.4);
}

.btn-disabled {
  background: #444;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Bottom Layout */
.bottom-layout {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.attributes-section,
.description-section {
  flex: 1;
  background: linear-gradient(135deg, #1a1a2e, #283044);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.attributes-section h3,
.description-section h3 {
  color: #54aeff;
  margin-bottom: 15px;
}

.attributes-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.attributes-list li {
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #eeeeee; /* Same color as the description placeholder */
}

.description-section p {
  font-size: 0.9rem;
  color: #eeeeee;
}

.after-confirmation-transaction-message {
  border: 1px solid #fff;
  box-shadow: 0 4px 10px #0006;
  border-radius: 10px;
  padding: 5px 15px;
  color: #fff;
  margin-top: 15px;
 background: rgb(36,34,51);
background: -moz-linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
background: -webkit-linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
background: linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#242233",endColorstr="#302e44",GradientType=1); 
}

.after-confirmation-transaction-message a {
  color: #ffc800;
  word-break: break-all;
}

.pre-transaction-message, .list_delist_tx_1, .purchase-container {
  margin-top: 15px;
  border: 1px solid #fff;
  box-shadow: 0 4px 10px #0006;
  border-radius: 10px;
  padding: 5px;
 background: rgb(36,34,51);
background: -moz-linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
background: -webkit-linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
background: linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#242233",endColorstr="#302e44",GradientType=1); 
}

.pre-transaction-message h3 {
  color: #1e88e5;
}

.pre-transaction-message ul {
  padding-left: 20px;
}

.pre-transaction-message li {
  list-style: none;
  margin-bottom: 10px;
}

.upr_prof {
  color: #1e88e5;
  font-weight: 700;
}

.load_tx_1 {
  max-width: 25px;
}

.prp_cl_1 {
  color: #f421ff;
}

.transfer-info-container {
  box-shadow: 0 4px 10px #0006;
  border: 1px solid #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px #0006;
  flex: 1 1;
  padding: 20px;
   background: rgb(36,34,51);
background: -moz-linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
background: -webkit-linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
background: linear-gradient(135deg, rgba(36,34,51,1) 0%, rgba(48,46,68,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#242233",endColorstr="#302e44",GradientType=1); 
}




/* Footer */
.details-footer {
  text-align: center;
  margin-top: 20px;
}

.details-footer .navbar-btn {
  margin-top: 10px;
}

/* Modal for Full Image */
.image-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  background-color: #000;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8);
}

.modalImageFull {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.acc_c_1 {
    color: #ffc800;
}

.user_url_c_1 {
  color: #ffc800;
}

.acc_c_0_alt {
  background-color: #5cff00;
  border-radius: 25px;
    color: #1a102d;
        font-size: 15px;
    font-weight: 500;
    padding: 5px 10px;
}

.acc_c_1_alt {
  background-color: #ff3c00;
  border-radius: 25px;
    color: #1a102d;
        font-size: 15px;
    font-weight: 500;
    padding: 5px 10px;
}

.upgrade-cont h4 {
  margin-bottom: 15px;
}

.upgrade-cont h3 {
  margin-bottom: 30px;
  color: #54aeff;
}

.gain-percentage-box p {
  margin: 0px 0px 5px;
}

.tx_err_msg_c1n {
  color: #ff2b2b;
  font-size: 16px;
  margin-top: 10px;
  transition: opacity .5s ease-in-out;
}

.tx_err_msg_c1n a {
  color: #20ff00;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

.tx_sccs_msg_c1n {
  color: #54aeff;
  font-size: 16px;
  margin-top: 10px;
  transition: opacity .5s ease-in-out;
}

.tx_sccs_msg_c1n a {
  color: #20ff00;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}



/* Responsive Design */
@media (max-width: 768px) {
  .topSection {
    flex-direction: column;
    align-items: center;
  }

  .imageContainer {
    height: auto;
    flex: auto;
  }

  .modalImage {
    width: 100%;
    height: auto;
    object-fit: contain;
  }


  .bottom-layout {
    flex-direction: column;
  }
}

@media (min-width: 1430px) {
    .container {
        max-width: 1430px;
        width: 100%;
    }
}