/* ========== General Page ========== */
.marketplace-page {
  padding: 40px 20px;
  background-color: var(--bg-deep);
  color: var(--text-light);
  font-family: var(--font-base);
  min-height: 100vh;
}

/* ========== Header ========== */
.page-header {
  text-align: center;
  margin-bottom: 40px;
}

.page-title {
  font-size: 2.6rem;
  font-weight: 800;
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textGradient 6s ease infinite;
}

@keyframes textGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* ========== Filters ========== */
.filter-section,
.filter-section-lstd {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 25px;
}

.search-bar,
.filter-dropdown {
  padding: 10px 16px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  background-color: #2b1b46;
  color: #fff;
  font-size: 15px;
  min-width: 180px;
  transition: border-color 0.2s;
}

.search-bar::placeholder {
  color: var(--text-muted);
  font-style: italic;
}

.filter-dropdown option {
  background-color: #1f1434;
  color: #fff;
}

/* ========== Toggle Listed CTAs ========== */
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: var(--bg-deep);
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch input:checked + .toggle-slider {
  background-color: var(--accent-blue);
}

.toggle-switch input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

.toggle-label {
  color: var(--text-muted);
  font-size: 14.5px;
}

/* ========== CTA Container ========== */
.ctasContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 24px;
  padding: 10px 10px 30px;
  max-width: 1280px;
  margin: auto;
}

.ctaBox {
  background: linear-gradient(135deg, #221538, #2c1f45);
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.35);
  padding: 18px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ctaBox:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 30px rgba(255, 255, 255, 0.15);
}

.ctaBox-img-cont-c1 {
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  padding: 4px;
  border-radius: 12px;
}

.ctaImage {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* ========== CTA Info ========== */
.ctaInfo {
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.ctaInfo h4 {
  font-size: 1.2rem;
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% 300%;
  animation: textGradient 8s ease infinite;
  margin-bottom: 8px;
}

.bx_info_cat_c_1,
.bx_info_col_c_1,
.bx_info_rst_c_1 {
  font-size: 14px;
  color: var(--text-muted);
}

.col_glb_c_1 {
  color: var(--accent-gold);
}

.col_prpl_c_1 {
  color: var(--accent-purple);
}

/* ========== Owner Info ========== */
.owner-info {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.08);
  padding: 8px 12px;
  border-radius: 12px;
  margin-top: 10px;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #fff;
  object-fit: cover;
}

.owner-text {
  display: flex;
  flex-direction: column;
}

.user-name-link {
  color: var(--accent-gold);
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
}

.wallet-address {
  font-size: 12.5px;
  color: #bbb;
  font-family: monospace;
}

/* ========== Sale Status ========== */
.sale-status {
  padding: 6px 14px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
}

.sale-status.listed {
  background: linear-gradient(135deg, #5cff00, #3ccf00);
  color: var(--bg-deep);
  box-shadow: 0 0 12px rgba(92, 255, 0, 0.6);
}

.sale-status.not-listed {
  background: linear-gradient(135deg, #ff3c00, #cf2200);
  color: var(--bg-deep);
  box-shadow: 0 0 12px rgba(255, 60, 0, 0.6);
}

/* ========== CTA Action Button ========== */
.btn_hpt {
  background-color: transparent;
  color: #fff;
  border: 2px solid #ffffff;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  transition: all 0.3s ease-in-out;
}

.btn_hpt:hover {
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  color: #fff;
  border-color: transparent;
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.25);
}

/* ========== Pagination ========== */
.pagination {
  display: flex;
  justify-content: center;
  margin: 40px auto;
  gap: 10px;
  flex-wrap: wrap;
}

.pagination button {
  padding: 10px 18px;
  border-radius: 6px;
  font-weight: 500;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  transition: 0.3s;
}

.pagination button:hover:not(:disabled) {
  background: var(--accent-purple);
  color: #fff;
}

.pagination button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.pagination .active-page {
  background: linear-gradient(135deg, #28233c, #382c58);
  color: var(--accent-gold);
  font-weight: 700;
  border-color: transparent;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.pagination .ellipsis {
  background: none;
  border: none;
  color: var(--text-muted);
  cursor: default;
  font-size: 20px;
}
