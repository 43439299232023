.footer {
background: rgb(19,11,34);
background: -moz-linear-gradient(0deg, rgba(19,11,34,1) 0%, rgba(29,16,52,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(19,11,34,1) 0%, rgba(29,16,52,1) 100%);
background: linear-gradient(0deg, rgba(19,11,34,1) 0%, rgba(29,16,52,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#130b22",endColorstr="#1d1034",GradientType=1);
  color: #ffffff;
  text-align: center;
  padding: 30px;
  position: relative;
  bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links a:hover {
  text-decoration: underline;
}
