:root {
  --accent-blue: #54aeff;
  --accent-purple: #9f51ff;
  --bg-dark: #0c0618;
  --text-light: #f2f2f2;
  --radius-md: 10px;
}

.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(10, 4, 20, 0.92);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.4);
}

.navbar-inner-container {
  max-width: 1440px;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Logo */
.logo-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  width: 44px;
  height: auto;
}

/* Menu Links */
.navbar-links {
  display: flex;
  align-items: center;
  gap: 18px;
  transition: all 0.3s ease;
}

.menu-item {
  padding: 8px 14px;
  color: var(--text-light);
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.menu-item:hover {
  background: rgba(255, 255, 255, 0.08);
  color: var(--accent-blue);
}

.menu-item.active {
  background: linear-gradient(135deg, #2a203d, #392c5c);
  border-bottom: 2px solid var(--accent-blue);
  color: var(--text-light);
}

.bridge-link {
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  color: #111;
  border-radius: 10px;
  font-weight: bold;
  padding: 8px 16px;
}

.bridge-link:hover {
  background: linear-gradient(135deg, #9055ff, #57c0ff);
  color: #000;
}

/* Social Icons */
.social-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.social-icon {
  color: var(--accent-blue);
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--accent-purple);
}

/* Wallet + Profile */
.navbar-wallet-group {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}

.profile-info-navbar {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.06);
  border-radius: var(--radius-md);
  padding: 6px 12px;
  gap: 8px;
  cursor: pointer;
}

.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--accent-blue);
}

.profile-name {
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  color: var(--text-light);
  font-weight: 600;
}

.sedra-energy-inline {
  font-size: 0.75rem;
  color: #facc15; /* cosmic golden */
  font-weight: 600;
  text-shadow: 0 0 4px rgba(255, 215, 100, 0.5);
}


/* Wallet Button */
.wallet-button {
  border: 2px solid var(--accent-blue);
  background: transparent;
  color: #fff;
  padding: 8px 16px;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.wallet-button:hover {
  background: var(--accent-blue);
  color: #000;
}

/* Wallet Dropdown */
.wallet-dropdown {
  position: absolute;
  top: 58px;
  right: 0;
  background: rgba(24, 16, 40, 0.95);
  border: 1px solid rgba(100, 60, 200, 0.4);
  border-radius: 16px;
  padding: 16px;
  width: 280px;
  backdrop-filter: blur(12px);
  color: var(--text-light);
  z-index: 999;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
}

.wallet-dropdown-close {
  position: absolute;
  top: 8px;
  right: 10px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  background: var(--accent-purple);
  color: white;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wallet-dropdown p {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.accent_clr_1 {
  color: var(--accent-blue);
  font-weight: 600;
}

.copy-wallet {
  cursor: pointer;
}

.copy-wallet:hover {
  text-decoration: underline;
}

.wallet-disconnect {
  width: 100%;
  margin-top: 10px;
  background: var(--accent-purple);
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-weight: 600;
}

.wallet-sub-btn {
  width: 100%;
  margin-top: 8px;
  background: transparent;
  border: 1px solid var(--accent-blue);
  color: #fff;
  padding: 8px;
  border-radius: 8px;
}

.wallet-sub-btn:hover {
  background: var(--accent-blue);
  color: #000;
}

/* Toggle */
.menu-toggle {
  display: none;
  font-size: 24px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

/* Responsive */
@media (max-width: 980px) {
  .navbar-inner-container {
    flex-wrap: wrap;
    gap: 12px;
  }

  .menu-toggle {
    display: block;
  }

  .navbar-links {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-top: 12px;
    display: none;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-wallet-group {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
  }

  .wallet-dropdown {
    top: 110px;
    right: 20px;
    width: 90vw;
  }
}
