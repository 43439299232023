/* /style/help.css */

.help-container {
  max-width: 960px;
  margin: 60px auto;
  padding: 48px;
  background: linear-gradient(145deg, #0f172a, #1e293b);
  border-radius: 20px;
  box-shadow: 0 0 24px rgba(165, 180, 252, 0.1);
  font-family: 'Inter', 'Segoe UI', Tahoma, sans-serif;
  color: #e2e8f0;
  line-height: 1.8;
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.help-title {
  font-size: 42px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 40px;
  background: linear-gradient(to right, #c084fc, #d946ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.8px;
  text-shadow: 0 2px 6px rgba(192, 132, 252, 0.25);
}

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #facc15;
  margin-top: 48px;
  margin-bottom: 20px;
  padding-left: 14px;
  background: linear-gradient(to right, #facc15, #fde68a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 1px 3px rgba(251, 191, 36, 0.15);
}

h2::after {
  content: '';
  display: block;
  height: 2px;
  width: 40px;
  margin-top: 8px;
  background: linear-gradient(to right, #6366f1, #a855f7);
  border-radius: 2px;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #cbd5e1;
}

ul,
ol {
  margin-left: 24px;
  margin-bottom: 20px;
  padding-left: 16px;
}

li {
  margin-bottom: 10px;
  font-size: 15px;
  color: #dbeafe;
}

strong {
  color: #f472b6;
  font-weight: 600;
}

a {
  color: #8b5cf6;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  text-decoration: underline;
}

.closing-line {
  margin-top: 56px;
  font-size: 18px;
  text-align: center;
  color: #f1f5f9;
  padding-top: 24px;
  border-top: 1px solid rgba(148, 163, 184, 0.2);
}

@media (max-width: 768px) {
  .help-container {
    padding: 32px 24px;
  }

  .help-title {
    font-size: 32px;
  }

  h2 {
    font-size: 20px;
  }

  p,
  li {
    font-size: 15px;
  }
}

/* Prevent unwanted pseudo-elements in Help only */
.help-container section::before,
.help-container div::before {
  content: none !important;
  display: none !important;
}
