/* =============== WRAPPER =============== */
.home-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  background: radial-gradient(circle at top, #0c0a18 0%, #090716 100%);
  color: #eaeaf3;
  font-family: var(--font-base);
  overflow-x: hidden;
}

/* =============== SECTION TITLES =============== */
.animated-section-title {
  font-size: 2.8rem;
  font-weight: 800;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 30px;
  background: linear-gradient(135deg, var(--accent-blue), #d946ef, var(--accent-gold));
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientGlow 8s ease infinite;
  letter-spacing: 1px;
  text-shadow: 0 2px 6px rgba(255, 255, 255, 0.06);
}

@keyframes gradientGlow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* =============== LIVE CTA STRIP =============== */
.live-cta-strip {
  margin-top: 30px;
  margin-bottom: 100px;
  overflow-x: auto;
  padding-bottom: 24px;
}

.cta-scroll {
  display: flex;
  gap: 20px;
  padding: 10px 32px;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.cta-tile {
  min-width: 160px;
  max-width: 180px;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.04));
  border-radius: 14px;
  padding: 12px;
  box-shadow: 0 0 18px rgba(84, 174, 255, 0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.04);
}

.cta-tile:hover {
  transform: translateY(-6px) scale(1.04);
  box-shadow: 0 0 22px rgba(255, 200, 0, 0.25);
}

.cta-img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.03);
}

.cta-meta {
  font-weight: 600;
  color: var(--accent-gold);
  font-size: 1rem;
}

.cta-meta-light {
  color: #a1a1aa;
  font-size: 0.85rem;
  margin-top: 2px;
}

.cta-meta-user {
  color: var(--accent-blue);
  font-size: 0.9rem;
  margin-top: 4px;
}

/* =============== ABOUT SECTION =============== */
.about-section {
  padding: 90px 10% 120px;
  background: radial-gradient(circle at center, #1a102e, #0c0918);
  position: relative;
  z-index: 2;
}

.about-description {
  text-align: center;
  color: #cfd3e6;
  font-size: 1.1rem;
  line-height: 1.7;
  padding: 0 6%;
  margin-top: 24px;
  font-weight: 400;
}

/* =============== RESPONSIVE =============== */
@media (max-width: 768px) {
  .cta-scroll {
    padding: 10px 16px;
    gap: 14px;
  }

  .cta-tile {
    min-width: 140px;
  }

  .cta-img {
    height: 120px;
  }

  .animated-section-title {
    font-size: 2rem;
    margin-top: 60px;
  }

  .about-description {
    font-size: 0.95rem;
    padding: 0 10%;
  }
}
