/* ========== GLOBAL DESIGN SYSTEM ========== */
:root {
  --accent-blue: #38bdf8;
  --accent-purple: #8b5cf6;
  --accent-gold: #facc15;
  --bg-dark: #10081c;
  --bg-darker: #0c0618;
  --text-light: #f4f4f5;
  --text-soft: #c3c3d1;
  --radius-md: 10px;
  --radius-lg: 18px;
  --font-main: 'Space Grotesk', 'Inter', sans-serif;
}

/* ========== RESET ========== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-main);
  background-color: var(--bg-dark);
  color: var(--text-light);
  line-height: 1.6;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-family: inherit;
  cursor: pointer;
  border: none;
  background: none;
  transition: all 0.3s ease;
}

img {
  max-width: 100%;
  display: block;
}

input,
select,
textarea {
  font-family: inherit;
  background: #1c102f;
  color: var(--text-light);
  border: 1px solid #333;
  padding: 8px 12px;
  border-radius: var(--radius-md);
  outline: none;
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--accent-blue);
  box-shadow: 0 0 0 2px rgba(56, 189, 248, 0.2);
}

/* ========== HEADINGS & TEXT ========== */
p {
  font-size: 1rem;
  color: var(--text-soft);
  margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: var(--text-light);
}

/* ========== CONTAINERS ========== */
.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
}

.main-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 20px;
}

.navbar-offset {
  margin-top: 80px;
}

/* ========== UTILITIES ========== */
.text-center { text-align: center; }
.flex { display: flex; }
.flex-col { flex-direction: column; }
.items-center { align-items: center; }
.justify-center { justify-content: center; }
.gap-2 { gap: 0.5rem; }
.gap-4 { gap: 1rem; }
.hidden { display: none; }

/* ========== CTA CARDS ========== */
.ctaBox p {
  font-size: 0.95rem;
  color: var(--text-soft);
  line-height: 1.4;
}
.ctaBox h4 {
  font-size: 1.1rem;
  color: var(--text-light);
  margin-bottom: 0.25rem;
}

/* ========== PROFILE IMAGE (CTAs & Codex) ========== */
.profile-avatar,
.cta-user-avatar,
.codex-profile-img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--accent-blue);
  box-shadow: 0 0 8px rgba(56, 189, 248, 0.3);
}

/* ========== BUTTONS ========== */
.btn-base,
.btn_hpt,
.admin-toggle-btn {
  padding: 8px 16px;
  border: 1px solid var(--accent-blue);
  border-radius: var(--radius-md);
  color: var(--text-light);
  background-color: transparent;
  font-weight: 500;
  transition: all 0.3s ease;
}
.btn-base:hover,
.btn_hpt:hover {
  background-color: var(--accent-blue);
  color: #000;
}

.admin-toggle-btn {
  margin: 16px auto;
  display: block;
  border-color: var(--accent-gold);
  color: var(--accent-gold);
  background-color: rgba(250, 204, 21, 0.1);
}
.admin-toggle-btn:hover {
  background-color: var(--accent-gold);
  color: #000;
}

/* ========== DROPDOWN ========== */
select.filter-dropdown {
  appearance: none;
  background-color: #1a102a;
  border: 1px solid var(--accent-blue);
  color: var(--text-light);
  border-radius: var(--radius-md);
  padding: 8px 12px;
  min-width: 180px;
  cursor: pointer;
  max-height: 200px;
  overflow-y: auto;
}

/* ========== ANIMATIONS ========== */
.fade-in {
  animation: fadeIn 0.6s ease forwards;
}
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* ========== HEADLINE GRADIENT ========== */
h1 span, h2 span, .gradient-title, .animated-gradient-title {
  background: linear-gradient(to right, var(--accent-blue), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

/* ========== SELECTION ========== */
::selection {
  background-color: var(--accent-blue);
  color: var(--bg-dark);
}

/* ========== SCROLLBAR ========== */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--accent-purple);
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #1a102a;
}

/* ========== MISC FIXES ========== */
hr.title-line {
  display: none !important;
}
