/* /frontend/src/components/style/TransferModal.css */

/* Modal overlay */
.modalOverlay_trsf {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal container */
.modalContainer_trsf {
    border-radius: 10px;
    color: #ffffff;
    box-shadow: 0 4px 6px #0000001a;
    max-width: 90%;
    padding: 20px;
    position: relative;
    width: 400px;
    z-index: 1001;
    background: rgb(46, 50, 75);
    background: -moz-linear-gradient(180deg, rgba(46, 50, 75, 1) 0%, rgba(1, 3, 15, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(46, 50, 75, 1) 0%, rgba(1, 3, 15, 1) 100%);
    background: linear-gradient(180deg, rgba(46, 50, 75, 1) 0%, rgba(1, 3, 15, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e324b",endColorstr="#01030f",GradientType=1);
    border: 1px solid #FFFFFF17;
}

.modalContainer_trsf h2 {
    color: #F421FF;
}

/* Close button */
.closeButton_trsf {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Success message */
.successMessage_trsf {
    color: green;
    font-weight: bold;
    margin-top: 10px;
}

/* Error message */
.error_trsf {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}
