/* 🌌 MetaCore Page Styles */
.metacore-container {
  color: white;
  text-align: center;
  padding: 50px 0px 100px;
}

/* 🚀 Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-video-container {
  width: 100%;
  height: auto;
}

.hero-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.hero-overlay {
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  padding: 50px;
  border-radius: 15px;
  backdrop-filter: blur(12px);
  max-width: 700px;
  position: absolute;
}

.hero-title {
  font-size: 4rem;
  font-weight: bold;
  color: #ffffff;
}

.hero-subtitle {
  font-size: 2rem;
  font-weight: 300;
  color: #d1d1ff;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.2rem;
  color: #b0b3ff;
  margin-bottom: 10px;
}

/* 🚀 MetaCore Insights */
.metacore-insights {
  max-width: 1100px;
  margin: 60px auto;
  padding: 40px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 15px;
  backdrop-filter: blur(12px);
}

.insights-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #ffffff;
}

.insights-list {
  list-style: none;
  padding: 0;
}

.insight-item {
  font-size: 1.2rem;
  color: #b0b3ff;
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 0.12);
  padding: 12px;
  border-radius: 8px;
}

/* 🚀 Chat Sections */
.chat-box {
  max-width: 900px;
  margin: 40px auto;
  padding: 25px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  text-align: center;
  backdrop-filter: blur(12px);
}

/* 🔹 Chat Messages */
.chat-messages {
  height: 400px;  /* Increased height for better visibility */
  overflow-y: auto;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column; /* Ensures latest messages appear at the bottom */
}

/* 🔹 Chat Message Styles */
.chat-message {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border-radius: 12px;
  font-size: 1rem;
  max-width: 85%;
  word-wrap: break-word;
}

/* User Messages */
.chat-message.user {
  background: rgba(255, 255, 255, 0.2);
  justify-content: flex-end;
  text-align: right;
  align-self: flex-end;
}

/* Companion Messages */
.chat-message.companion {
  background: rgba(106, 90, 205, 0.5);
  justify-content: flex-start;
  text-align: left;
  align-self: flex-start;
}

/* 🔹 Profile Image Small Circle */
.message-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

/* 🔹 Chat Input */
.chat-input {
  display: flex;
  gap: 12px;
  align-items: center;
  background: rgba(255, 255, 255, 0.15); /* Slightly more visible */
  border-radius: 12px;
  padding: 12px;
  width: 100%; /* Ensure full width */
  max-width: 900px;
  margin: 0 auto; /* Centered alignment */
}

/* 🔹 Message Input Box */
.chat-input textarea {
  flex: 1;
  padding: 14px;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  background: rgba(255, 255, 255, 0.2); /* Darker background for better visibility */
  color: white;
  outline: none;
  resize: none;
  min-height: 50px;
  max-height: 120px; /* Allow expansion for multi-line messages */
  overflow-y: auto; /* Scroll if message is too long */
}

.chat-input textarea::placeholder {
  color: #b0b3ff;
  opacity: 0.8;
}

/* 🔹 Send Button */
.chat-input button {
  padding: 14px 22px;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  background: #6a5acd;
  color: white;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s ease-in-out;
  font-weight: bold;
}

.chat-input button:hover {
  background: #4b3caf;
  transform: scale(1.05); /* Subtle effect */
}

.chat-input button:disabled {
  background: rgba(106, 90, 205, 0.3); /* Disabled look */
  cursor: not-allowed;
}


/* 🚀 Leaderboard */
.leaderboard {
  max-width: 1000px;
  margin: 50px auto;
  padding: 35px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.leaderboard-title {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 20px;
}

.leaderboard-list {
  list-style: none;
  padding: 0;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1.2rem;
  color: #b0b3ff;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s;
}

.leaderboard-item:hover {
  transform: scale(1.05);
}

/* 🔹 Leaderboard Profile Image */
.leaderboard-image {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #6a5acd;
}

/* 🚀 Group Chat */
.group-chat {
  max-width: 1100px;
  margin: 50px auto;
  padding: 50px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 15px;
  backdrop-filter: blur(12px);
}

.chat-title {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 15px;
}

/* 🚀 Global Sections */
.content-section {
  margin: 60px auto;
  padding: 50px;
  display: flex;
  align-items: center;
  gap: 40px;
  border-radius: 15px;
  backdrop-filter: blur(12px);
  box-shadow: 0 4px 12px #0006;
  background: linear-gradient(135deg, rgba(36, 34, 51, 1) 0%, rgba(48, 46, 68, 1) 100%);
}

.content-section.reverse {
  flex-direction: row-reverse;
}

.text-panel {
  flex: 1;
}

.section-title {
  font-size: 2rem;
  color: #ffffff;
}

.section-description {
  font-size: 1.2rem;
  color: #b0b3ff;
  margin-bottom: 15px;
}

.key-features {
  list-style: none;
  padding: 0;
}

.key-features li {
  font-size: 1.1rem;
  color: #9eaaff;
  margin-bottom: 10px;
}

.video-container {
  width: 45%;
}

.content-video {
  width: 100%;
  border-radius: 12px;
}
