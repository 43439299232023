/* ========== GENERAL ========== */
.page-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px 16px;
  color: #e2e4ec;
  font-family: 'Inter', sans-serif;
  position: relative;
}

/* ========== TITLE BANNER ========== */
.user-banner {
  text-align: center;
  margin-bottom: 20px;
}

.page-title {
  font-size: 2.6rem;
  font-weight: 700;
  color: var(--accent-gold);
  background: linear-gradient(to right, var(--accent-blue), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ========== PROFILE SECTION ========== */
.profile-picture-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 28px;
  position: relative;
}

.profile-pic-overlay {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--accent-blue);
  box-shadow: 0 0 12px rgba(56, 189, 248, 0.4);
  background-color: #1a1525;
  position: relative;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  display: block;
}

.upload-btn {
  position: absolute;
  bottom: 4px;
  right: 4px;
  background: var(--accent-purple);
  color: white;
  border-radius: 4px;
  font-size: 0.7rem;
  padding: 2px 6px;
  cursor: pointer;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 0.95rem;
  color: #cbd5e1;
}

.user-details span {
  color: var(--accent-gold);
  font-weight: 500;
}

.edit-btn, .save-btn {
  margin-left: 8px;
  font-size: 0.75rem;
  background: var(--accent-blue);
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.edit-name-input {
  font-size: 0.9rem;
  padding: 2px 4px;
  border-radius: 4px;
  background: #1f1f2b;
  border: 1px solid #334155;
  color: #fff;
}

/* ========== METRICS ========== */
.profile-metrics {
  display: flex;
  justify-content: center;
  gap: 48px;
  margin: 24px 0;
  flex-wrap: wrap;
}

.profile-i_1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 0.95rem;
}

.profile-i_1 span {
  color: var(--accent-blue);
  font-weight: 600;
}

/* ========== CTA FILTERS ========== */
.filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  justify-content: center;
  margin: 20px 0 32px;
}

.search-bar,
.filter-dropdown {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #1e1b2f;
  border: 1px solid #334155;
  color: #e2e4ec;
  font-size: 0.95rem;
  min-width: 180px;
  outline: none;
}

.search-bar:focus,
.filter-dropdown:focus {
  border-color: var(--accent-blue);
}

/* ========== CTA GRID ========== */
.ctasContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  margin-bottom: 60px;
}

.ctaBox {
  background: linear-gradient(to bottom right, #15121f, #1c1a2f);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #2a2a40;
  padding: 12px;
  transition: transform 0.2s ease;
}

.ctaBox:hover {
  transform: translateY(-4px);
}

.ctaImage {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 12px;
}

.ctaInfo h4 {
  font-size: 1.1rem;
  color: var(--accent-gold);
  margin-bottom: 4px;
}

.ctaInfo p {
  font-size: 0.9rem;
  color: #c3c7d4;
  margin: 2px 0;
}

.acc_c_0_alt {
  color: #16f2a9;
  font-weight: bold;
}

.acc_c_1_alt {
  color: #ef4444;
  font-weight: bold;
}

.btn_hpt {
  margin-top: 10px;
  padding: 6px 10px;
  background: var(--accent-blue);
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn_hpt:hover {
  background: #0ea5e9;
}

/* ========== PAGINATION ========== */
.pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 80px;
  flex-wrap: wrap;
}

.pagination button {
  padding: 6px 12px;
  background-color: #1e1b2f;
  border: 1px solid #334155;
  color: #cbd5e1;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination button:hover {
  background-color: var(--accent-purple);
  color: #fff;
}

.active-page {
  background-color: var(--accent-gold);
  color: #000;
  font-weight: bold;
}

/* ========== RESPONSIVE ========== */
@media (max-width: 768px) {
  .profile-picture-container {
    flex-direction: column;
    text-align: center;
  }

  .profile-pic-overlay {
    width: 90px;
    height: 90px;
  }

  .upload-btn {
    font-size: 0.6rem;
    padding: 2px 4px;
  }

  .edit-name-input {
    width: 80%;
  }

  .user-details,
  .profile-i_1 {
    text-align: center;
    font-size: 0.95rem;
  }

  .filter-section {
    flex-direction: column;
    align-items: center;
  }

  .search-bar,
  .filter-dropdown {
    width: 90%;
  }

  .ctasContainer {
    grid-template-columns: 1fr;
  }
}
