.insights-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

h2 {
    text-align: center;
    color: #333;
}

.error-message {
    color: red;
    text-align: center;
}

.insights-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.insight-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.insight-item h4 {
    margin: 0;
    font-size: 18px;
    color: #444;
}

.insight-item p {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
}
