/* ================= GATEWAY WRAPPER ================= */
.gateway-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  position: relative;
  transition: background 0.5s ease;
  overflow: hidden;
  z-index: 1;
}

/* ================= GATEWAY CARD ================= */
.gateway-card {
  background: rgba(20, 14, 40, 0.85);
  padding: 48px;
  border-radius: 24px;
  max-width: 880px;
  width: 100%;
  box-shadow: 0 0 28px rgba(84, 174, 255, 0.15);
  backdrop-filter: blur(8px);
  position: relative;
  z-index: 2;
  text-align: center;
  color: #e6e8f0;
  animation: fadeIn 1s ease;
}

/* ================= SECTION TITLE ================= */
.gateway-title {
  font-size: 2.8rem;
  background: linear-gradient(135deg, #54aeff, #f421ff, #ffc800);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  margin-bottom: 14px;
  letter-spacing: 1px;
  text-shadow: 0 2px 8px rgba(255, 255, 255, 0.08);
}

.gateway-title::before {
  content: none !important;
  display: none !important;
}

.gateway-subtitle {
  font-size: 1.3rem;
  color: #facc15;
  margin-bottom: 36px;
  font-style: italic;
  text-shadow: 0 1px 4px rgba(250, 204, 21, 0.2);
}

/* ================= DESCRIPTION ================= */
.gateway-description p {
  font-size: 1.05rem;
  margin: 12px 0;
  line-height: 1.8;
  color: #d2d5e0;
}

/* ================= CONTROLS ================= */
.gateway-controls {
  margin-top: 36px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.gateway-controls button {
  background: linear-gradient(135deg, #54aeff, #f421ff);
  color: #1a102d;
  font-weight: 700;
  border: none;
  padding: 14px 28px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 0 12px rgba(84, 174, 255, 0.15);
}

.gateway-controls button:hover {
  transform: scale(1.06);
  box-shadow: 0 0 18px rgba(255, 200, 0, 0.3);
}

/* ================= SEDRA ENERGY DISPLAY ================= */
.gateway-score {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffc800;
  margin-bottom: 24px;
  animation: pulseGlow 2s infinite;
  text-shadow: 0 0 8px rgba(255, 200, 0, 0.4);
}

@keyframes pulseGlow {
  0%   { text-shadow: 0 0 8px rgba(255, 200, 0, 0.4); }
  50%  { text-shadow: 0 0 16px rgba(255, 200, 0, 0.9); }
  100% { text-shadow: 0 0 8px rgba(255, 200, 0, 0.4); }
}

/* ================= ANIMATIONS ================= */
.animated-fade {
  animation: fadeIn 1s ease forwards;
}

.animated-card {
  animation: fadeInUp 0.8s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(30px); }
  to   { opacity: 1; transform: translateY(0); }
}

/* ================= RESPONSIVE ================= */
@media (max-width: 768px) {
  .gateway-card {
    padding: 32px 20px;
  }

  .gateway-title {
    font-size: 2.2rem;
  }

  .gateway-subtitle {
    font-size: 1.1rem;
  }

  .gateway-score {
    font-size: 1.25rem;
  }

  .gateway-description p {
    font-size: 0.95rem;
  }

  .gateway-controls button {
    width: 100%;
    max-width: 300px;
  }
}

